import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer } from "@mui/material";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

export default function Menudrower() {
  const [open1, setOpen1] = React.useState(false);
  const { open } = useWeb3Modal();
  const { address, isConnected, isDisconnected } = useAccount();

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen1(true);
  };

  const handleDrawerClose = () => {
    setOpen1(false);
  };
  return (
    <div>
      <MenuIcon
        onClick={() => handleDrawerOpen()}
        className="!text-5xl text-blue-300"
      />
      <Drawer anchor="top" open={open1} onClose={handleDrawerClose}>
        <div className=" relative flex flex-col gap-10 items-center py-10  justify-center px-10">
          <a href="/" className="font-bold border-b w-full">
            HOME
          </a>
          <a
            onClick={handleDrawerClose}
            href="#about"
            className="font-bold border-b w-full"
          >
            ICO
          </a>
          <a
            onClick={handleDrawerClose}
            href="#token"
            className="font-bold border-b w-full"
          >
            TOKEN
          </a>
          <a
            onClick={handleDrawerClose}
            href="#Roadmap"
            className="font-bold border-b w-full"
          >
            ROADMAP
          </a>
          <a
            onClick={handleDrawerClose}
            href="#team"
            className="font-bold border-b w-full"
          >
            TEAM
          </a>
          <a
            onClick={handleDrawerClose}
            href="#press"
            className="font-bold border-b w-full"
          >
            PRESS
          </a>
          <span className="">
            {address ? (
              <p
                onClick={() => {
                  navigate("/dashboard");
                  handleDrawerClose();
                }}
                className="py-3 w-44 text-center px-2 bg-blue-400 rounded-lg cursor-pointer text-black font-bold  hover:bg-black hover:border-2 hover:border-blue-400 hover:text-white"
              >
                BUY IMT TOKEN
              </p>
            ) : (
              <p
                onClick={() => {
                  open();
                  handleDrawerClose();
                }}
                className="py-3 px-10 bg-blue-400 rounded-lg cursor-pointer text-black font-bold  hover:bg-black hover:border-2 hover:border-blue-400 hover:text-white"
              >
                REGISTER
              </p>
            )}
          </span>
        </div>
      </Drawer>
    </div>
  );
}
