import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import image from "../../Images/banner.jpg";
import log from "../../Images/logo-lite.png";
import about from "../../Images/about-img.png";
import icon1 from "../../Images/icon-1.png";
import icon2 from "../../Images/icon-2.png";
import icon3 from "../../Images/icon-3.png";
import icon4 from "../../Images/icon-4.png";
import icon5 from "../../Images/icon-5.png";
import icon6 from "../../Images/icon-6.png";
import imt from "../../Images/IMT.png";
import sh from "../../Images/shadow.png";
import token from "../../Images/tokenomics.png";
import trustwallet from "../../Images/trustwallet.png";
import metamask from "../../Images/metamask.png";
import pancakeswap from "../../Images/pancakeswap.png";
import poocoin from "../../Images/poocoin.png";
import play from "../../Images/playstore.png";
import app from "../../Images/appstore.png";
import dow from "../../Images/downloadApp.png";
import Footer from "../../Components/Footer";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { useNavigate, useNavigation } from "react-router-dom";
import Menudrower from "../../Components/Menudrower";

export default function Home() {
  const { open } = useWeb3Modal();
  const { address, isConnected, isDisconnected } = useAccount();
  const navigate = useNavigate();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "April, 10, 2024";

  const endime = Date.parse(deadline);

  const timeNow = Date.now();

  const time = Date.parse(deadline) - Date.now();

  const getTime = () => {
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };
  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, [timeNow]);
  return (
    <div>
      {/* <Header /> */}
      <div
        className=" lg:h-[900px] relative overflow-hidden flex flex-col items-center bg-blue-400  bg-opacity-5 "
        style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
      >
        <div className="flex items-center justify-between bg-black text-white w-full lg:px-52 py-10">
          <div>
            <img src={log} alt="" className="h-16  object-contain" />
          </div>
          <div className="w-32">
            <p
              onClick={() => open()}
              className="text-ellipsis overflow-hidden cursor-pointer"
            >
              {address}
            </p>
          </div>

          <div className="flex items-center gap-5 justify-between lg:w-[50%]  ">
            <a href="/" className="font-bold lg:block hidden">
              HOME
            </a>
            <a href="#about" className="font-bold lg:block hidden">
              ICO
            </a>
            <a href="#token" className="font-bold lg:block hidden">
              TOKEN
            </a>
            <a href="#Roadmap" className="font-bold lg:block hidden">
              ROADMAP
            </a>
            <a href="#team" className="font-bold lg:block hidden">
              TEAM
            </a>
            <a href="#press" className="font-bold lg:block hidden">
              PRESS
            </a>
            {address ? (
              <p
                onClick={() => navigate("/dashboard")}
                className="py-3 w-44 text-center px-2 bg-blue-400 rounded-lg cursor-pointer text-black font-bold lg:block hidden hover:bg-black hover:border-2 hover:border-blue-400 hover:text-white"
              >
                BUY IMT TOKEN
              </p>
            ) : (
              <p
                onClick={() => open()}
                className="py-3 px-10 bg-blue-400 rounded-lg cursor-pointer text-black font-bold lg:block hidden hover:bg-black hover:border-2 hover:border-blue-400 hover:text-white"
              >
                REGISTER
              </p>
            )}
          </div>
          <div className="pr-5 lg:hidden">
            <Menudrower />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center lg:px-96">
          <p className="lg:text-6xl text-3xl  text-white text-center font-bold">
            Insta Millionaire - Empowering Transactions with{" "}
            <d className="text-blue-300 font-normal">
              Smart Contract Innovation
            </d>
          </p>
          <div className="lg:flex border-2 border-blue-400 rounded-md w-full my-5 lg:p-10 p-2 pre-sale-timer justify-between gap-10">
            <div className="lg:flex flex-col items-center justify-between ">
              <p className="text-white lg:text-4xl text-4xl font-bold text-center">
                Pre-Sale
                <p className="text-white font-normal my-5">
                  Ended
                  {/* {endime > timeNow ? "will end in" : "Started"} */}
                </p>
              </p>

              {timeNow ? (
                <div className="flex my-5 items-center justify-center lg:gap-5 gap-1 lg:w-[500px] ">
                  {/* <span className="flex flex-col text-white border-2 lg:px-5 lg:py-3 p-2 lg:my-0 my-5 rounded-md border-blue-400">
                    <p className="text-2xl font-bold text-center">{days}</p>
                    <p>Days</p>
                  </span>
                  <p className="text-3xl font-bold text-white lg:block hidden">
                    :
                  </p>
                  <span className="flex flex-col text-white border-2 lg:px-5 lg:py-3 p-2 lg:my-0 my-5 rounded-md border-blue-400">
                    <p className="text-2xl font-bold text-center">{hours}</p>
                    <p>hours</p>
                  </span>
                  <p className="text-3xl font-bold text-white lg:block hidden">
                    :
                  </p>
                  <span className="flex flex-col text-white border-2 lg:px-5 lg:py-3 p-2 lg:my-0 my-5 rounded-md border-blue-400">
                    <p className="text-2xl font-bold text-center">{minutes}</p>
                    <p>minutes</p>
                  </span>
                  <p className="text-3xl font-bold text-white lg:block hidden">
                    :
                  </p>
                  <span className="flex flex-col text-white border-2 lg:px-5 lg:py-3 p-2 rounded-md border-blue-400">
                    <p className="text-2xl font-bold text-center">{seconds}</p>
                    <p>seconds</p>
                  </span> */}
                </div>
              ) : (
                <div className="text-white font-bold font-weight-bold text-center py-3">
                  Buy Insta Millionaire token Now!
                </div>
              )}
              {address ? (
                <p
                  onClick={() => navigate("/dashboard1")}
                  className=" w-full font-bold py-3 hover:bg-transparent text-white text-center my-3 bg-black cursor-pointer rounded-sm hover:border hover:border-black"
                >
                  CLAIM TOKENS NOW
                </p>
              ) : (
                <p
                  onClick={() => open()}
                  className=" w-full font-bold py-3 hover:bg-transparent text-white text-center my-3 bg-black cursor-pointer rounded-sm hover:border hover:border-black"
                >
                  REGISTER & BUY TOKENS NOW
                </p>
              )}
            </div>
            <div className="flex flex-col items-center justify-center w-full ">
              <div class="rang-slider-main">
                <div class="rang-slider-toltip">
                  <span>
                    Soft Cap <strong>$25,624</strong>
                  </span>
                  <span>
                    Hard Cap <strong>$100,000</strong>
                  </span>
                </div>
                <div class="rang-slider">
                  <div class="rang-line">
                    <span></span>
                  </div>
                </div>
                <div class="rang-slider-total">
                  <span>
                    Total raised <strong>$91,000</strong>
                  </span>
                  <div class="rangTotal">
                    91<small>%</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="about"
        className="flex items-start justify-start gap-10 bg-white lg:py-20 lg:px-96 p-5"
      >
        <div className="flex flex-col lg:w-[50%]">
          <p className="text-4xl font-bold text-black my-2">Why to choose</p>
          <p className="text-6xl font-bold text-black my-2">
            Insta Millionaire?
          </p>
          <p className="text-2xl text-blue-500 my-2">
            Welcome to Insta Millionaire's platform on revolutionizing the
            future of finance through Blockchain technology.
          </p>
          <p className="text-gray-500 my-2">
            Our innovative token solutions are changing the game for financial
            institutions worldwide. Join us as we explore the possibilities. In
            a world rapidly evolving towards digitalization, the Insta
            Millionaire token emerges as a beacon of innovation, offering a
            transformative approach to secure and efficient digital
            transactions.
          </p>
          <p className="text-gray-500 my-2">
            With its revolutionary architecture and advanced features, the Insta
            Millionaire token is poised to redefine the way we engage in
            financial exchanges, data management, and beyond.
          </p>
          {/* {address ? (
            <p
              onClick={() => navigate("/dashboard")}
              className="py-3 lg:w-[25%] text-center bg-blue-400 rounded-lg cursor-pointer text-black font-bold hover:bg-black hover:border-2 hover:border-blue-400 my-5 hover:text-white"
            >
              PRE SALE
            </p>
          ) : (
            <p
              onClick={() => open()}
              className="py-3 lg:w-[25%] text-center bg-blue-400 rounded-lg cursor-pointer text-black font-bold hover:bg-black hover:border-2 hover:border-blue-400 my-5 hover:text-white"
            >
              REGISTER
            </p>
          )} */}
        </div>
        <div className="flex items-center justify-center">
          <img src={about} alt="" className="object-contain" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center  bg-black lg:py-20 lg:px-96 p-5">
        <p className="text-4xl font-bold text-white">
          Benefits of Using Our Platform
        </p>
        <p className="text-gray-300 text-xl my-3 ">
          Experience innovation, collaboration, and growth in a dynamic
          ecosystem designed for success.
        </p>
        <div className="lg:flex lg:gap-10 my-5">
          <div className="flex gap-5 border-2 border-gray-500 hover:border-blue-400 lg:my-2 my-5 lg:p-10 p-2 rounded-md lg:w-[50%]">
            <img src={icon1} alt="" className="object-contain" />
            <span>
              <p className="text-2xl font-bold text-white">Hassle Free</p>
              <p className="text-gray-400">
                IMT aims to deliver an exceptionally convenient, hassle-free,
                stable, and encrypted transactional network. Our vision is to
                evolve into an integrated super-community, encompassing all
                major decentralized autonomous communities.
              </p>
            </span>
          </div>
          <div className="flex gap-5 border-2 border-gray-500 hover:border-blue-400 lg:p-10 p-2 rounded-md lg:w-[50%]">
            <img src={icon2} alt="" className="object-contain" />
            <span>
              <p className="text-2xl font-bold text-white">
                Stable community platform
              </p>
              <p className="text-gray-400">
                IMT is a community-centered platform dedicated to fostering
                financial independence and economic stability through our
                digital asset management platform. We're proud contributors to
                various blockchain initiatives, driving innovation and progress.
              </p>
            </span>
          </div>
        </div>
        <div className="lg:flex gap-10 g:my-5">
          <div className="flex gap-5 border-2 border-gray-500 hover:border-blue-400 lg:p-10 p-2 rounded-md lg:my-0 my-5 lg:w-[50%]">
            <img src={icon3} alt="" className="object-contain" />
            <span>
              <p className="text-2xl font-bold text-white">
                Transact Digitally
              </p>
              <p className="text-gray-400">
                Transact Digitally IMT aims to bridge the trust gap between
                traditional financial services and decentralized communities,
                amplifying digital transactions without compromising stakeholder
                positions.
              </p>
            </span>
          </div>
          <div className="flex gap-5 border-2 border-gray-500 hover:border-blue-400 lg:p-10 p-2 rounded-md lg:my-0 my-5 lg:w-[50%]">
            <img src={icon4} alt="" className="object-contain" />
            <span>
              <p className="text-2xl font-bold text-white">
                Referral Staking system
              </p>
              <p className="text-gray-400">
                Invite your friends and share our referral link with them to
                earn commission rewards whenever they conduct transactions using
                IMT Token.
              </p>
            </span>
          </div>
        </div>
        <div className="lg:flex gap-10 lg:my-5">
          <div className="flex gap-5 border-2 border-gray-500 hover:border-blue-400 lg:p-10 p-2 lg:my-0 my-5 rounded-md lg:w-[50%]">
            <img src={icon5} alt="" className="object-contain" />
            <span>
              <p className="text-2xl font-bold text-white">
                Lowest ever exchange fee
              </p>
              <p className="text-gray-400">
                We guarantee the lowest exchange fees ever on our DeFi platform,
                providing a secure environment for cryptocurrency transactions.
              </p>
            </span>
          </div>
          <div className="flex gap-5 border-2 border-gray-500 hover:border-blue-400 lg:p-10 p-2 lg:my-0 my-5 rounded-md lg:w-[50%]">
            <img src={icon6} alt="" className="object-contain" />
            <span>
              <p className="text-2xl font-bold text-white">
                Trade fee reimbursements
              </p>
              <p className="text-gray-400">
                At our IMT platform, you can perform exchanges or transactions
                without incurring any additional fees!
              </p>
            </span>
          </div>
        </div>
      </div>
      <div
        id="token"
        className="flex flex-col items-center justify-center lg:gap-10 bg-white  lg:py-20 p-5 lg:px-96"
      >
        <p className="text-4xl font-bold">IMT Pre-Sale</p>
        <div className="lg:flex my-5 gap-10">
          <div class="token-sale-box w-full text-center">
            <p className="text-center font-bold border-b py-3 text-4xl">
              Pre-Sale
            </p>
            <div className="flex  flex-col justify-between items-center">
              <span className="flex flex-col my-5">
                <p className="text-white text-xl font-bold">Token Allocated</p>
                <p className="text-2xl font-bold text-black">10 Cr.</p>
              </span>
              <span className="flex flex-col my-5">
                <p className="text-white text-xl font-bold">
                  Pre-Sale Start Date
                </p>
                <p className="text-2xl font-bold text-black">March 10, 2024</p>
              </span>
              <span className="flex flex-col my-5">
                <p className="text-white text-xl font-bold">
                  Pre-Sale End Date
                </p>
                <p className="text-2xl font-bold text-black">April 10, 2024</p>
              </span>
              <span className="flex flex-col my-5">
                <p className="text-white text-xl font-bold">Token Price</p>
                <p className="text-2xl font-bold text-black">$0.08</p>
              </span>
            </div>
          </div>
          <div className="flex flex-col lg:my-0 my-5 ">
            <img src={imt} alt="" className="object-contain" />
            <img src={sh} alt="" className="object-contain" />
          </div>
        </div>
        <p className="lg:text-4xl text-2xl my-5 font-bold">
          Distribution of Tokens
        </p>
        <div className="lg:flex items-center gap-20 justify-center">
          <img src={token} alt="" className="h-[400px] object-contain" />
          <div className="flex flex-col">
            <span className="flex items-center  gap-5 my-3">
              <p className="p-1 w-3 h-3 bg-sky-500"></p>
              <p className="text-xl text-gray-500">Exchange</p>
            </span>
            <span className="flex items-center  gap-5 my-3">
              <p className="p-1 w-3 h-3 bg-blue-600"></p>
              <p className="text-xl text-gray-500">
                Marketing+Research & Development and NFT Game Development
              </p>
            </span>
            <span className="flex items-center  gap-5 my-3">
              <p className="p-1 w-3 h-3 bg-purple-500"></p>
              <p className="text-xl text-gray-500">Staking</p>
            </span>
            <span className="flex items-center  gap-5 my-3">
              <p className="p-1 w-3 h-3 bg-pink-500"></p>
              <p className="text-xl text-gray-500">Open Circulation</p>
            </span>
            <span className="flex items-center  gap-5 my-3">
              <p className="p-1 w-3 h-3 bg-red-500"></p>
              <p className="text-xl text-gray-500">Liquidity</p>
            </span>
            <span className="flex items-center  gap-5 my-3">
              <p className="p-1 w-3 h-3 bg-green-500"></p>
              <p className="text-xl text-gray-500">Promotion</p>
            </span>
          </div>
        </div>
      </div>
      <div
        id="Roadmap"
        className="flex flex-col items-center justify-center  bg-black lg:py-20 lg:px-96 p-5"
      >
        <p className="text-4xl font-bold text-white">The Roadmap</p>
        <p className="text-center text-gray-400 text-xl my-2">
          Our roadmap outlines an ambitious journey aimed at enriching user
          experience and broadening platform capabilities.
        </p>

        <div className="lg:flex gap-5 my-10">
          {" "}
          <div className="text-black flex flex-col lg:py-0 py-5 lg:w-52 px-1 bg-white rounded-md lg:my-0 my-3 ">
            <p className="text-blue-500 font-bold text-xl text-center">
              Q4 - 2023
            </p>
            <p className="font-bold text-center">
              Token Concept Development and Research
            </p>
          </div>
          <div className="text-black flex flex-col lg:py-0 py-5 lg:w-52 px-1 bg-white rounded-md lg:my-0 my-3 ">
            <p className="text-blue-500 font-bold text-xl text-center">
              Q4 - 2023
            </p>
            <p className="font-bold text-center">
              Token Concept Development and Research
            </p>
          </div>
          <div className="text-black flex flex-col lg:py-0 py-5 lg:w-52 px-1 bg-white rounded-md lg:my-0 my-3 ">
            <p className="text-blue-500 font-bold text-xl text-center">
              Q4 - 2023
            </p>
            <p className="font-bold text-center">
              Token Concept Development and Research
            </p>
          </div>
          <div className="text-black flex flex-col lg:py-0 py-5 lg:w-52 px-1 bg-white rounded-md lg:my-0 my-3 ">
            <p className="text-blue-500 font-bold text-xl text-center">
              Q4 - 2023
            </p>
            <p className="font-bold text-center">
              Token Concept Development and Research
            </p>
          </div>
          <div className="text-black flex flex-col lg:py-0 py-5 lg:w-52 px-1 bg-white rounded-md lg:my-0 my-3 ">
            <p className="text-blue-500 font-bold text-xl text-center">
              Q4 - 2023
            </p>
            <p className="font-bold text-center">
              Token Concept Development and Research
            </p>
          </div>
          <div className="text-black flex flex-col lg:py-0 py-5 lg:w-52 px-1 bg-white rounded-md lg:my-0 my-3 ">
            <p className="text-blue-500 font-bold text-xl text-center">
              Q4 - 2023
            </p>
            <p className="font-bold text-center">
              Token Concept Development and Research
            </p>
          </div>
          <div className="text-black flex flex-col lg:py-0 py-5 lg:w-52 px-1 bg-white rounded-md lg:my-0 my-3 ">
            <p className="text-blue-500 font-bold text-xl text-center">
              Q4 - 2023
            </p>
            <p className="font-bold text-center">
              Token Concept Development and Research
            </p>
          </div>
          <div className="text-black flex flex-col lg:py-0 py-5 lg:w-52 px-1 bg-white rounded-md lg:my-0 my-3 ">
            <p className="text-blue-500 font-bold text-xl text-center">
              Q4 - 2023
            </p>
            <p className="font-bold text-center">
              Token Concept Development and Research
            </p>
          </div>
        </div>
      </div>
      <div
        id="press"
        className="flex flex-col items-center justify-center gap-10 bg-white lg:py-20 lg:px-96 p-5"
      >
        <p className="lg:text-4xl text-2xl font-bold">Decentralized Wallets</p>
        <div className="lg:flex gap-10">
          <img src={trustwallet} alt="" className="h-64 object-contain" />
          <img src={metamask} alt="" className="h-64 object-contain" />
        </div>
        <p className="lg:text-4xl text-2xl font-bold">Decentralized Wallets</p>
        <div className="lg:flex gap-10">
          <img src={pancakeswap} alt="" className="h-64 object-contain" />
          <img src={poocoin} alt="" className="h-64 object-contain" />
        </div>
      </div>
      <div className="lg:flex justify-center items-center download-app gap-10">
        <div className="flex flex-col lg:justify-start  lg:items-start items-center">
          <p className="text-4xl font-bold text-white ">Download Our App</p>
          <p className="text-xl text-white">
            Get access to our exclusive features by downloading our app today!
          </p>
          <div className="lg:flex gap-5 lg:my-10 ">
            <img
              src={play}
              className="h-16 object-contain lg:my-0 my-3 "
              alt=""
            />
            <img src={app} className="h-16 object-contain" alt="" />
          </div>
        </div>
        <img src={dow} alt="" className="lg:h-[500px] object-contain" />
      </div>
      <Footer />
    </div>
  );
}
