import React, { useEffect, useState } from "react";
import image from "../../Images/banner.jpg";
import logo1 from "../../Images/IMT.png";
import succe from "../../Images/success.gif";
import axiosInstance1 from "../../Config1/axios";
import { API_URLS1 } from "../../Config1/apiUrls";
import { toast } from "react-toastify";

export default function Register() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("ref");
  const [page, setPage] = useState(false);
  const [sponserName, setSponserName] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [sponserId, setSponserId] = useState(token);
  const [errors, setErrors] = useState({});
  const [registerData, setRagisterData] = useState();

  const Activ = () => {
    axiosInstance1
      .post(API_URLS1.checksponsor, { U_id: token ? token : sponserId })
      .then((res) => {
        setSponserName(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Activ();
  }, [sponserId]);

  const validateForm = () => {
    let valid = true;
    const errorsObj = {};

    // Validate name
    if (!name) {
      errorsObj.name = "Name is required";
      valid = false;
    }

    // Validate email
    if (!email) {
      errorsObj.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errorsObj.email = "Email is invalid";
      valid = false;
    }

    // Validate number
    if (!number) {
      errorsObj.number = "Number is required";
      valid = false;
    } else if (!/^\d{10}$/.test(number)) {
      errorsObj.number = "Number must be 10 digits";
      valid = false;
    }

    // Validate password
    if (!password) {
      errorsObj.password = "Password is required";
      valid = false;
    }

    setErrors(errorsObj);
    return valid;
  };

  const Register = () => {
    if (validateForm()) {
      axiosInstance1
        .post(API_URLS1.Register_1, {
          name: name,
          Email: email,
          Mobile: number,
          Password: password,
          sponsor: sponserName?.[0].Id,
        })
        .then((res) => {
          setRagisterData(res.data);
          setPage(true);

          setNumber("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const sentOtp = () => {
    axiosInstance1
      .post(API_URLS1.SendMail_50, {
        name: name,
        fromEmail: email,
        otp: password,
        Id: registerData.Data,
      })
      .then((res) => {
        setSponserName(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    registerData && sentOtp();
  }, [registerData]);

  return (
    <div
      className="h-screen relative lg:justify-center lg:items-center  flex flex-col  bg-blue-400  bg-opacity-5 p-5 overflow-auto   "
      style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
    >
      <div className="flex my-5  justify-center items-center gap-5">
        <img src={logo1} className="h-20 w-20 object-contain" alt="" />
        <p className="lg:text-4xl text-3xl  text-white text-center font-bold">
          Insta Millionaire
        </p>
      </div>
      {page ? (
        <div className="flex flex-col h-full justify-center items-center  lg:w-[30%] lg:h-[20%] w-full">
          <img src={succe} alt="" />

          <span className="flex text-green-400 gap-2">
            <p>UserId:</p>
            <p className="text-white">{registerData.Data}</p>
          </span>
          <span className="flex text-green-400">
            <p>Password:</p>
            <p className="text-white">{registerData.Password}</p>
          </span>
          <p className="text-green-400">Register Successfully...</p>
        </div>
      ) : (
        <div className="flex flex-col lg:px-10  lg:w-[40%]  rounded-md">
          <p className="text-3xl font-bold text-blue-400 my-5">Register</p>
          {sponserName?.[0].name && (
            <div className="flex flex-col w-full my-2 bg-opacity-10 bg-white ">
              <input
                value={sponserName?.[0].name}
                type="text"
                placeholder="Sponsor Name"
                className="w-full bg-inherit border-2 py-3 rounded-md pl-3 text-xl outline-none border-gray-500  text-white"
                disabled={true}
              />
            </div>
          )}

          <div className="flex flex-col w-full my-2 ">
            <input
              value={sponserId}
              onChange={(e) => setSponserId(e.target.value)}
              type="text"
              placeholder="Sponsor Id"
              className="w-full bg-inherit border-2 py-3 rounded-md pl-3 text-xl outline-none border-gray-500  text-white"
            />
          </div>
          <div className="flex flex-col w-full my-2 ">
            <input
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              placeholder="Full Name"
              className="w-full bg-inherit border-2 py-3 rounded-md pl-3 text-xl outline-none border-gray-500  text-white"
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}
          </div>
          <div className="flex flex-col w-full my-2 ">
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              placeholder="Email Id"
              className="w-full bg-inherit border-2 py-3 rounded-md pl-3 text-xl outline-none border-gray-500  text-white"
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>
          <div className="flex flex-col w-full my-2 ">
            <input
              onChange={(e) => setNumber(e.target.value)}
              value={number}
              type="number"
              placeholder="Mobile Number"
              className="w-full bg-inherit border-2 py-3 rounded-md pl-3 text-xl outline-none border-gray-500  text-white"
            />
            {errors.number && <p className="text-red-500">{errors.number}</p>}
          </div>
          <div className="flex flex-col w-full my-2 ">
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              placeholder="Enter password"
              className="w-full bg-inherit border-2 py-3 rounded-md pl-3 text-xl outline-none border-gray-500  text-white"
            />
            {errors.password && (
              <p className="text-red-500">{errors.password}</p>
            )}
          </div>
          <button
            onClick={Register}
            className="w-full bg-inherit border-2 hover:bg-blue-500 border-white py-2 rounded-md pl-3 text-xl text-white font-bold outline-none my-5"
          >
            Register
          </button>
        </div>
      )}
    </div>
  );
}
