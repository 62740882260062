import logo from "./logo.svg";
import "./App.css";

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { bsc, bscTestnet, mainnet } from "viem/chains";
// import { Staking } from "./Component/Context/StakingApp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Page/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Page/Dashboard";
import Test from "./Page/Test";
import { Staking } from "./Components/Context/StakingApp";
import Register from "./Page/Register";
import Swap from "./Page/Swap";

function App() {
  const projectId = "0345c7230f52356034d20c7c5fbf2519";
  // 2. Create wagmiConfig
  const metadata = {
    name: "InstaMillionaire",
    description:
      "Welcome to InstaMillionaire, It is future of innovation and finance",
    url: "https://instamillionaire.io/",
  };

  const chains = [bsc, bscTestnet];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

  // 3. Create modal
  createWeb3Modal({ wagmiConfig, projectId, chains });
  return (
    <WagmiConfig config={wagmiConfig}>
      <BrowserRouter>
        <Staking>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/dashboard1" element={<Dashboard />} />
            <Route path="/dashboard" element={<Swap />} />
            <Route path="/register" element={<Register />} />
            <Route path="/test" element={<Test />} />
          </Routes>
        </Staking>
      </BrowserRouter>
      <ToastContainer />
    </WagmiConfig>
  );
}

export default App;
