import React, { useContext, useEffect, useState } from "react";
import logo1 from "../../Images/IMT.png";
import LanguageIcon from "@mui/icons-material/Language";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useAccount, useDisconnect, useSwitchNetwork } from "wagmi";
import ReactApexChart from "react-apexcharts";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import log from "../../Images/logo-lite.png";
import { useChainId } from "wagmi";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { useNavigate } from "react-router-dom";
import { StakingApp } from "../../Components/Context/StakingApp";
import Menudrower from "../../Components/Menudrower";
import moment from "moment";

export default function Dashboard() {
  const { address } = useAccount();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const { open } = useWeb3Modal();
  const navigate = useNavigate();
  const { switchNetwork } = useSwitchNetwork();
  const {
    usdtAddress,
    InstamillionaireAddress,
    name,
    symbol,
    totalSaply,
    softCap,
    time,
    setAmount,
    amount,
    allowanceamount,
    approveusd,
    buy,
    cliamval,
    token,
    buyamount,
    buyamount1,
    contributer,
    val1,
    cliam,
    val2,
  } = useContext(StakingApp);
  const handleDate = (val) => {
    const date = new Date(val);
    const formattedDate = date.toLocaleDateString();

    return formattedDate;
  };
  const { selectedNetworkId } = useWeb3ModalState();

  const date = () => {
    return moment(handleDate(time)).format("MM,DD,YYYY");
  };

  const getTime = (dal) => {
    const times = Date.parse(dal) - Date.now();

    setDays(Math.floor(times / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((times / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((times / 1000 / 60) % 60));
    setSeconds(Math.floor((times / 1000) % 60));
  };

  useEffect(() => {
    const deadline = date();
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, [time != null]);

  const data = {
    series: [20, 5, 5, 18, 10, 20, 22],
    options: {
      chart: {
        type: "donut",
      },
      labels: [
        "Liquidity",
        "Promotion",
        "Rewards and Promotion",
        "Exchange",
        "Marketing + Reserch & Development and NFT Game Development",
        "Staking",
        "Open Circulation",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  // useEffect(() => {
  //   !address && navigate("/");
  // }, [address]);

  return (
    <div className="flex flex-col bg-gray-200 ">
      {val1 || val2}
      {/* <Header /> */}
      <div className="flex items-center justify-between bg-black text-white w-full lg:px-52 py-5">
        <div>
          <img src={log} alt="" className="h-24" />
        </div>
        <div className="w-32">
          <p
            onClick={() => open()}
            className="text-ellipsis overflow-hidden cursor-pointer"
          >
            {address}
          </p>
        </div>

        <div className="flex items-center gap-5 justify-between lg:w-[50%]  ">
          <a href="/" className="font-bold lg:block hidden">
            HOME
          </a>
          <a href="#about" className="font-bold lg:block hidden">
            ICO
          </a>
          <a href="#token" className="font-bold lg:block hidden">
            TOKEN
          </a>
          <a href="#Roadmap" className="font-bold lg:block hidden">
            ROADMAP
          </a>
          <a href="#team" className="font-bold lg:block hidden">
            TEAM
          </a>
          <a href="#press" className="font-bold lg:block hidden">
            PRESS
          </a>
          {address ? (
            <p
              onClick={() => navigate("/dashboard")}
              className="py-3 w-32 text-center px-2 bg-blue-400 rounded-lg cursor-pointer text-black font-bold lg:block hidden hover:bg-black hover:border-2 hover:border-blue-400 hover:text-white"
            >
              PRE SALE
            </p>
          ) : (
            <p
              onClick={() => open()}
              className="py-3 px-10 bg-blue-400 rounded-lg cursor-pointer text-black font-bold lg:block hidden hover:bg-black hover:border-2 hover:border-blue-400 hover:text-white"
            >
              REGISTER
            </p>
          )}
        </div>
        <div className="pr-5 lg:hidden">
          <Menudrower />
        </div>
      </div>
      <div className="lg:flex lg:py-10 gap-10 lg:px-52 ">
        <div className="lg:w-[70%] ">
          <div className="bg-white p-5 ">
            <div className="lg:flex gap-5">
              {/* <div className="border border-blue-500 rounded-full p-1 lg:h-16 lg:w-16"> */}
              <img src={logo1} className="h-20 w-20 object-contain" alt="" />
              {/* </div> */}
              <div className="flex flex-col w-full">
                <div className="lg:flex w-full justify-between">
                  <div className="flex flex-col">
                    <p className="text-xl font-bold text-blue-400">
                      Insta Millionaire Fair Launch
                    </p>
                    <span className="flex gap-5 my-2">
                      <LanguageIcon className="!text-blue-400" />
                      <XIcon className="!text-blue-400" />
                      <TelegramIcon className="!text-blue-400" />
                    </span>
                  </div>

                  <p className="text-sm h-8 text-green-500 bg-gray-300 py-1 lg:px-5 px-2 rounded-xl text-center">
                    sale live
                  </p>
                </div>
                <p>
                  Insta Millionaire(IMT) on Binance Smart Chain (BSC) is
                  revolutionary. Team buys tokens during sale. Our mission:
                  fully decentralized, fee-free, immutable transactions. IMT
                  liquidity plan: 70% for pool, ensuring stability & growth, 5%
                  for fast track CMC & CG listing. Let's soar with IMT!
                </p>
              </div>
            </div>
            <div className="flex flex-col my-5">
              <span className="flex w-full justify-between border-b p-2">
                <p>Presale Address</p>
                <p className="text-blue-500 text-ellipsis overflow-hidden">
                  {usdtAddress}
                </p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Token Name</p>
                <p className="">{name}</p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Token Symbol</p>
                <p className="">{symbol}</p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Token address</p>
                <p className="text-blue-500 text-ellipsis overflow-hidden">
                  {InstamillionaireAddress}
                </p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Token Decimals</p>
                <p className="text-blue-500 text-ellipsis overflow-hidden">
                  18
                </p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Total Supply</p>
                <p className="">
                  {totalSaply} {symbol}
                </p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Tokens For Presale</p>
                <p className="">100000000 Cr</p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Tokens For Liquidity</p>
                <p className="">70000000 Cr</p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Initial Market Cap (estimate)</p>
                <p className="">100000000 Cr</p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Soft Cap</p>
                <p className="">{5000} USDT</p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Presale Start Time</p>
                <p className="">2024.03.10 </p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Presale End Time</p>
                <p className="">{handleDate(time)}</p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Listing On</p>
                <p className="text-blue-500">Pancakeswap</p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Liquidity Percent</p>
                <p className="">70%</p>
              </span>
              <span className="flex w-full justify-between border-b p-2">
                <p>Liquidity Lockup Time</p>
                <p className="">{365} days after pool ends</p>
              </span>
            </div>
          </div>
          <div className="flex flex-col my-5 p-5 bg-white">
            <p className="text-xl font-bold text-black py-3 border-b">
              Token Metrics
            </p>
            <div className="flex justify-center items-center py-5">
              <ReactApexChart
                options={data.options}
                series={data.series}
                type="donut"
                style={{ width: "800px" }}
              />
            </div>
          </div>
        </div>
        <div className=" flex flex-col lg:w-[30%]">
          <div className="flex flex-col bg-white p-3 rounded-md">
            <div className="flex flex-col my-5 gap-5 w-full items-center">
              <p>Claim your IMT token's now</p>
              {selectedNetworkId === 56 ? (
                <button
                  type="submit"
                  disabled={cliamval}
                  className={`${
                    !cliamval
                      ? "py-1.5 rounded-lg bg-blue-500 px-10 text-white w-full "
                      : "px-8 py-3 text-white bg-blue-300 rounded focus:outline-none cursor-not-allowed w-full "
                  }`}
                  onClick={() => cliam()}
                >
                  Claim
                </button>
              ) : (
                <button
                  type="submit"
                  className="py-1.5 rounded-lg bg-blue-500 px-10 text-white w-full"
                  onClick={() => switchNetwork?.(56)}
                >
                  Switch Network To BSC
                </button>
              )}
            </div>
            {/* <p className="lg:px-5 px-2 border border-yellow-500 py-1 rounded-lg bg-yellow-50">
              Make sure the website is https://instamillionaire.io/ !
            </p>
            <div className="flex flex-col justify-center items-center my-5">
              <p className="my-2">Presale Ends In</p>
              <div className="flex justify-center items-center gap-5">
                <span className=" flex items-center justify-center h-10 w-9 bg-blue-50 rounded-lg">
                  {days}
                </span>
                <span className=" flex items-center justify-center h-10 w-9 bg-blue-50 rounded-lg">
                  {hours}
                </span>
                <span className=" flex items-center justify-center h-10 w-9 bg-blue-50 rounded-lg">
                  {minutes}
                </span>
                <span className=" flex items-center justify-center h-10 w-9 bg-blue-50 rounded-lg">
                  {seconds}
                </span>
              </div>
            </div>
            <div className="flex flex-col">
              <BorderLinearProgress variant="determinate" value={softCap} />
              <span className="w-full flex justify-end items-end my-1">
                <p className="">{softCap} USDT</p>
              </span>
            </div>
            {allowanceamount === 0 ? (
              ""
            ) : (
              <div className="flex flex-col ">
                <p className="font-sans">Amount</p>
                <div className="flex w-full justify-between border pl-5 py-1 rounded-md border-gray-500 px-2 my-2">
                  <input
                    type="text"
                    placeholder="Minimum buy 10$"
                    className="outline-none w-full"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <p
                    onClick={() => setAmount(10000)}
                    className="font-bold text-blue-500 cursor-pointer"
                  >
                    MAX
                  </p>
                </div>
              </div>
            )} */}

            {/* <div className="flex my-5 gap-5 items-center">
              {selectedNetworkId === 56 ? (
                allowanceamount === 0 ? (
                  <button
                    type="submit"
                    disabled={val1}
                    className={`${
                      !val1
                        ? "py-1.5 rounded-lg bg-blue-500 px-10 text-white "
                        : "px-8 py-3 text-white bg-blue-300 rounded focus:outline-none cursor-not-allowed"
                    }`}
                    onClick={() => approveusd()}
                  >
                    Approve
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={val2}
                    className={`${
                      !val2
                        ? "py-1.5 rounded-lg bg-blue-500 px-10 text-white "
                        : "px-8 py-3 text-white bg-blue-300 rounded focus:outline-none cursor-not-allowed"
                    }`}
                    onClick={() => buy()}
                  >
                    Buy
                  </button>
                )
              ) : (
                <button
                  type="submit"
                  className="py-1.5 rounded-lg bg-blue-500 px-10 text-white"
                  onClick={() => switchNetwork?.(56)}
                >
                  Switch Network To BSC
                </button>
              )}
            </div>{" "} */}
          </div>
          <div className=" flex flex-col bg-white p-3 rounded-md my-5">
            {/* <Test /> */}
            <div className="flex flex-col ">
              <div className="flex items-center justify-center">
                <KeyboardArrowDownIcon className="!text-blue-300" />
              </div>
              <div className="flex w-full flex-col">
                <span className="flex w-full justify-between py-2 border-b my-1">
                  <p>Token Received</p>
                  <p className="">{buyamount} IMT</p>
                </span>
                <span className="flex w-full justify-between py-2 border-b my-1">
                  <p>Your Purchase</p>
                  <p className="">{buyamount1} USDT</p>
                </span>
                <span className="flex w-full justify-between py-2 border-b my-1">
                  <p>Current Rate</p>
                  <p className="">
                    1 USDT = {token?.toFixed(3)} {symbol}
                  </p>
                </span>
                <span className="flex w-full justify-between py-2 border-b my-1">
                  <p>Total Contributors</p>
                  <p className="">{contributer}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
