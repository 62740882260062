import React from "react";
import log from "../../Images/logo-lite.png";

export default function Footer() {
  return (
    <>
      {" "}
      <div className="lg:flex gap-10 bg-black lg:px-52 lg:py-20 p-5">
        <div className="flex items-start flex-col lg:w-[20%]">
          <img src={log} alt="" className="h-20 object-contain my-5" />
          <p className="text-gray-400">
            Our innovative token solutions are changing the game for financial
            institutions worldwide. Join us as we explore the possibilities.
          </p>
        </div>
        <div className="flex flex-col lg:w-[30%] lg:my-0 my-5">
          <p className="text-2xl font-bold text-white">Pages</p>
          <div className="flex gap-10 text-white my-5">
            <span className="flex flex-col justify-between">
              <p className=" font-bold text-gray-500">About Pre-Sale</p>
              <p className=" font-bold text-gray-500">Roadmap</p>
              <p className=" font-bold text-gray-500">Privacy policy</p>
            </span>

            <span className="flex flex-col justify-between">
              <p className=" font-bold text-gray-500">Tokenomics IMT</p>
              <p className=" font-bold text-gray-500">Register</p>
              <p className=" font-bold text-gray-500">Terms & conditions</p>
            </span>
          </div>
        </div>
        <div className="flex flex-col lg:w-[30%]">
          <p className="text-2xl font-bold text-white">Newsletter</p>
          <div className="flex gap-10 text-white my-5">
            <span className="flex flex-col justify-between">
              <p className=" font-bold text-gray-500">About Pre-Sale</p>
              <p className=" font-bold text-gray-500">Roadmap</p>
              <p className=" font-bold text-gray-500">Privacy policy</p>
            </span>

            <span className="flex flex-col justify-between">
              <p className=" font-bold text-gray-500">Tokenomics IMT</p>
              <p className=" font-bold text-gray-500">Register</p>
              <p className=" font-bold text-gray-500">Terms & conditions</p>
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <p className="text-center">© 2024 INSTA MILLIONAIRE.</p>
      </div>
    </>
  );
}
