import React, { useContext, useEffect, useState } from "react";
import image from "../../Images/banner.jpg";
import RepeatIcon from "@mui/icons-material/Repeat";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import usdt from "../../Images/USDT.png";
import imt from "../../Images/IMT.png";
import { StakingApp } from "../../Components/Context/StakingApp";
import { useAccount } from "wagmi";
import log from "../../Images/logo-lite.png";
import { useNavigate } from "react-router-dom";
export default function Swap() {
  const {
    usdttoken,
    setUsdttoken,
    imtToken,
    setImttoken,
    usdtAllowance,
    imttAllowance,
    usdtapproveusd,
    imtapproveusd,
    swapUsdt,
    swapimt,
    imtbalance,
    usdtbalance,
    openStatus,
  } = useContext(StakingApp);
  const { address } = useAccount();
  const [checked, setChecked] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    setChecked(openStatus ? false : true);
  }, [openStatus]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleIMTTokenChange = (e) => {
    setImttoken(e.target.value);
    setUsdttoken((e.target.value * 1000) / 10000);
  };
  const handleUsdtTokenChange = (e) => {
    setUsdttoken(e.target.value);
    setImttoken((e.target.value * 100000) / 10000);
  };

  return (
    <div
      className="h-screen relative  flex flex-col  bg-blue-400  bg-opacity-5 p-5 overflow-auto   "
      style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
    >
      <div className="flex justify-between items-center gap-5">
        <div onClick={() => navigate("/")}>
          <img src={log} alt="" className="h-24" />
        </div>
        <span className="flex text-white truncate">
          <p className="font-bold"></p>
          <p className="font-bold text-ellipsis overflow-hidden">
            {address.slice(0, 20)}...
          </p>
        </span>
      </div>
      <div className="lg:justify-center lg:items-center  flex flex-col w-full mt-24">
        <div className="border lg:p-10 p-5 pre-sale-timer text-white lg:w-[40%] rounded-2xl">
          <div className="flex flex-col border-b py-1 gap-2">
            <p className="text-2xl font-bold ">Swap</p>
            <span className="flex gap-1">
              <p>Convert IMT </p>
              <RepeatIcon className="!text-md" />
              <p>USDT from here</p>
            </span>
          </div>
          {!checked && (
            <div className="flex flex-col lg:p-5 p-2 border rounded-md my-10">
              <div className="flex gap-2 justify-between">
                <input
                  type="number"
                  placeholder="0"
                  value={imtToken}
                  onChange={handleIMTTokenChange}
                  className="outline-none rounded-md p-2 w-[70%] text-black"
                />
                <span className="flex border lg:gap-5 gap-2 rounded-lg items-center justify-center bg-gray-400 p-1 w-[30%]">
                  <img src={imt} className="h-7 w-7" alt="" />
                  <p className="font-bold text-black">IMT</p>
                </span>
              </div>
              <div className="flex gap-5 justify-between mt-2">
                <p className="font-bold text-white "></p>
                <p className="font-bold text-white ">
                  IMT~$ {(imtbalance / 10 ** 18).toFixed(3)}
                </p>
              </div>
            </div>
          )}
          {checked && (
            <div className="flex flex-col lg:p-5 p-2 border rounded-md my-10">
              <div className="flex gap-2 justify-between">
                <input
                  type="number"
                  placeholder="0"
                  value={usdttoken}
                  onChange={handleUsdtTokenChange}
                  className="outline-none rounded-md p-2 w-[70%] text-black"
                />
                <span className="flex border lg:gap-5 gap-2 rounded-lg items-center justify-center bg-gray-400 p-1 w-[30%]">
                  <img src={usdt} className="h-7 w-7 rounded-full" alt="" />
                  <p className="font-bold text-black">USDT</p>
                </span>
              </div>
              <div className="flex gap-5 justify-between mt-2">
                <p className="font-bold text-white "></p>
                <p className="font-bold text-white ">
                  USDT~$ {(usdtbalance / 10 ** 18).toFixed(3)}
                </p>
              </div>
            </div>
          )}
          {openStatus && (
            <div className="flex justify-center items-center">
              <span
                onClick={handleChange}
                className="h-10 w-10 bg-gray-300  flex items-center justify-center rounded-full cursor-pointer hover:bg-gray-100"
              >
                <ArrowDownwardIcon className="hover:text-black" />
              </span>
            </div>
          )}

          {!checked && (
            <div className="flex flex-col lg:p-5 p-2 border rounded-md my-10">
              <div className="flex gap-2 justify-between">
                <input
                  type="number"
                  placeholder="0"
                  value={usdttoken}
                  onChange={handleUsdtTokenChange}
                  className="outline-none rounded-md p-2 w-[70%] text-black"
                />
                <span className="flex border lg:gap-5 gap-2 rounded-lg items-center justify-center bg-gray-400 p-1 w-[30%]">
                  <img src={usdt} className="h-7 w-7 rounded-full" alt="" />
                  <p className="font-bold text-black">USDT</p>
                </span>
              </div>
              <div className="flex gap-5 justify-between mt-2">
                <p className="font-bold text-white "></p>
                <p className="font-bold text-white ">
                  {" "}
                  USDT~$ {(usdtbalance / 10 ** 18).toFixed(3)}
                </p>
              </div>
            </div>
          )}
          {checked && (
            <div className="flex flex-col lg:p-5 p-2 border rounded-md my-10">
              <div className="flex gap-2 justify-between">
                <input
                  type="number"
                  placeholder="0"
                  value={imtToken}
                  onChange={handleIMTTokenChange}
                  className="outline-none rounded-md p-2 w-[70%] text-black"
                />
                <span className="flex border lg:gap-5 gap-2 rounded-lg items-center justify-center bg-gray-400 p-1 w-[30%]">
                  <img src={imt} className="h-7 w-7" alt="" />
                  <p className="font-bold text-black">IMT</p>
                </span>
              </div>
              <div className="flex gap-5 justify-between mt-2">
                <p className="font-bold text-white "></p>
                <p className="font-bold text-white ">
                  {" "}
                  IMT~$ {(imtbalance / 10 ** 18).toFixed(3)}
                </p>
              </div>
            </div>
          )}

          {/* <div className="flex w-full justify-between my-10">
          <p className="text-white">Minimum received after slippage (5%)</p>
          <p className="text-white font-bold">0.25 USDT</p>
        </div> */}
          {checked ? (
            usdtAllowance > 0 ? (
              <p
                onClick={swapUsdt}
                className=" w-full font-bold py-3 hover:bg-transparent text-white text-center  bg-black cursor-pointer rounded-sm hover:border hover:border-black my-10"
              >
                Swap
              </p>
            ) : (
              <p
                onClick={usdtapproveusd}
                className=" w-full font-bold py-3 hover:bg-transparent text-white text-center  bg-black cursor-pointer rounded-sm hover:border hover:border-black my-10"
              >
                Approve
              </p>
            )
          ) : imttAllowance > 0 ? (
            <p
              onClick={swapimt}
              className=" w-full font-bold py-3 hover:bg-transparent text-white text-center  bg-black cursor-pointer rounded-sm hover:border hover:border-black my-10"
            >
              Swap
            </p>
          ) : (
            <p
              onClick={imtapproveusd}
              className=" w-full font-bold py-3 hover:bg-transparent text-white text-center  bg-black cursor-pointer rounded-sm hover:border hover:border-black my-10"
            >
              Approve
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
