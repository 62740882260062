"use Server";
import React, { useState, createContext } from "react";
import { useAccount, useContractRead, useContractWrite } from "wagmi";
import {
  usdtAddress,
  usdtAbi1,
  InstamillionaireAddress,
  InstamillionaireAbi,
  Presealtoken,
  PresealAbi,
  usdtToken,
  Abiusdt,
  Contrect,
  imttoken,
  Abiimt,
  ContrectAbi,
} from "./constant";
import { toast } from "react-toastify";

export const StakingApp = createContext();

export const Staking = ({ children }) => {
  const { address } = useAccount();
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [totalSaply, setTotalSaply] = useState("");
  const [softCap, setSoftCap] = useState("");
  const [time, setTime] = useState(null);
  const [amount, setAmount] = useState();
  const [allowanceamount, setAllowanceamount] = useState();
  const [token, setToken] = useState();
  const [buyamount, setBuyamount] = useState();
  const [buyamount1, setBuyamount1] = useState();
  const [contributer, setContributer] = useState();
  const [usdttoken, setUsdttoken] = useState(0);
  const [imtToken, setImttoken] = useState(0);
  const [usdtAllowance, setusdtAllowance] = useState();
  const [imttAllowance, setimttAllowance] = useState();
  const [imtbalance, setimtbalance] = useState();
  const [usdtbalance, setusdtbalance] = useState();
  const [openStatus, setOpenStatus] = useState();

  useContractRead({
    address: InstamillionaireAddress,
    abi: InstamillionaireAbi,
    functionName: "name",
    // args: [address],
    onSuccess(data) {
      setName(data.toString());
    },

    watch: true,
  });

  useContractRead({
    address: InstamillionaireAddress,
    abi: InstamillionaireAbi,
    functionName: "symbol",
    // args: [address],
    onSuccess(data) {
      setSymbol(data.toString());
    },

    watch: true,
  });
  useContractRead({
    address: InstamillionaireAddress,
    abi: InstamillionaireAbi,
    functionName: "totalSupply",
    // args: [address],
    onSuccess(data) {
      setTotalSaply(Number(data) / 10 ** 18);
    },

    watch: true,
  });
  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "_totalTokenSale",
    // args: [address],
    onSuccess(data) {
      setSoftCap(Number(data) / 10 ** 18);
    },

    watch: true,
  });

  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "_endSale",
    // args: [address],
    onSuccess(data) {
      setTime(Number(data) * 1000);
    },

    watch: true,
  });

  useContractRead({
    address: usdtAddress,
    abi: usdtAbi1,
    functionName: "allowance",
    args: [address, Presealtoken],
    onSuccess(data) {
      setAllowanceamount(Number(data));
    },
    watch: true,
  });
  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "getToken",
    args: ["1000000000000000000"],
    onSuccess(data) {
      setToken(Number(data) / 10 ** 18);
    },

    watch: true,
  });

  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "purchases",
    args: [address],
    onSuccess(data) {
      setBuyamount(Number(data[0]) / 10 ** 18);
      setBuyamount1(Number(data[1]) / 10 ** 18);
    },

    watch: true,
  });
  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "_totalContributer",
    // args: [address],
    onSuccess(data) {
      setContributer(Number(data));
    },

    watch: true,
  });

  const { write: approveusd, isLoading: val1 } = useContractWrite({
    address: usdtAddress,
    abi: usdtAbi1,
    functionName: "approve",
    args: [
      Presealtoken,
      "1157920892373161954235709850086879078532699846656405640394575840079131296399",
    ],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });
  const { write: buy, isLoading: val2 } = useContractWrite({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "buyTokens",
    args: [amount * 10 ** 18],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });

  const { write: cliam, isLoading: cliamval } = useContractWrite({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "claimTokens",

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });
  //swap
  useContractRead({
    address: usdtToken,
    abi: Abiusdt,
    functionName: "allowance",
    args: [address, Contrect],
    onSuccess(data) {
      setusdtAllowance(Number(data));
    },
    watch: true,
  });
  useContractRead({
    address: imttoken,
    abi: Abiimt,
    functionName: "allowance",
    args: [address, Contrect],
    onSuccess(data) {
      setimttAllowance(Number(data));
    },
    watch: true,
  });

  useContractRead({
    address: imttoken,
    abi: Abiimt,
    functionName: "balanceOf",
    args: [address],
    onSuccess(data) {
      setimtbalance(Number(data));
    },
    watch: true,
  });
  useContractRead({
    address: usdtToken,
    abi: Abiusdt,
    functionName: "balanceOf",
    args: [address],
    onSuccess(data) {
      setusdtbalance(Number(data));
    },
    watch: true,
  });

  useContractRead({
    address: Contrect,
    abi: ContrectAbi,
    functionName: "open",

    onSuccess(data) {
      setOpenStatus(data);
    },
    watch: true,
  });

  const { write: usdtapproveusd, isLoading: val5 } = useContractWrite({
    address: usdtToken,
    abi: Abiusdt,
    functionName: "approve",
    args: [
      Contrect,
      "1157920892373161954235709850086879078532699846656405640394575840079131296399",
    ],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });
  const { write: imtapproveusd, isLoading: val6 } = useContractWrite({
    address: imttoken,
    abi: Abiimt,
    functionName: "approve",
    args: [
      Contrect,
      "1157920892373161954235709850086879078532699846656405640394575840079131296399",
    ],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });

  const { write: swapUsdt, isLoading: val7 } = useContractWrite({
    address: Contrect,
    abi: ContrectAbi,
    functionName: "swapUSDT",
    args: [usdttoken * 10 ** 18],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });

  const { write: swapimt, isLoading: val8 } = useContractWrite({
    address: Contrect,
    abi: ContrectAbi,
    functionName: "swapTokens",
    args: [imtToken * 10 ** 18],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });

  //stakin 1 end
  return (
    <StakingApp.Provider
      value={{
        name,
        symbol,
        usdtAddress,
        InstamillionaireAddress,
        totalSaply,
        softCap,
        time,
        setAmount,
        amount,
        allowanceamount,
        approveusd,
        buy,
        token,
        buyamount,
        buyamount1,
        contributer,
        val1,
        val2,
        cliam,
        cliamval,
        usdttoken,
        setUsdttoken,
        imtToken,
        setImttoken,
        usdtAllowance,
        imttAllowance,
        usdtapproveusd,
        imtapproveusd,
        swapUsdt,
        swapimt,
        imtbalance,
        usdtbalance,
        openStatus,
      }}
    >
      {children}
    </StakingApp.Provider>
  );
};
